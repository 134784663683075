

import img1 from '../images/slider/mockup.png'
import img2 from '../images/slider/darulUlumBanner.png'
import img3 from '../images/slider/ny2025.png'
import mobile1 from '../images/slider/darulUlumBanner-mobile.png'
import mobile2 from '../images/slider/merdeka2024-mobile.png'


const dataBanner = [
    {
        id: 1,
        
        heading: 'Beramal Tanpa Had Dimana Jua, Setiap Masa  ',
        desc: 'Al-Jariyah merupakan gerbang utama ke semua perkhidmatan berkaitan Islam yang ditawarkan oleh institusi berdaftar di bawah satu platform.',
        img: img1,
    },
    {
        id: 2,
        
        heading: ' ',
        desc: '',
        img: img2,
        imgMobile: mobile1,
    },
    {
        id: 3,
        
        heading: ' ',
        desc: '',
        img: img3,
        imgMobile: mobile2,
    },

    
    

]

export default dataBanner;