const menus = [
    {
        id: 1,
        name: 'Laman Utama',
        links: '/',
        
    },
    
    {
        id: 2,
        name: 'Mengenai Al-Jariyah',
        links: '/mengenai-aljariyah',
    },
    {
        id: 3,
        name: 'Senarai Institusi',
        links: '/senarai-institusi',
    },
    {
        id: 4,
        name: 'Berita & Aktiviti',
        links: '/berita-aktiviti',

       
    },
    
    {
        id: 5,
        name: 'Tambahan',
        links: '#',
        namesub: [
            {
                id: 1,
                sub: 'Pendaftaran Institusi ',
                // links: 'https://cpdemo-kariah.al-jariyah.com/register'
                links: 'https://cp.al-jariyah.com/register'
            },
            {
                id: 2,
                sub: 'Log Masuk Institusi',
                // links: 'https://cpdemo-kariah.al-jariyah.com/'
                links: 'https://cp.al-jariyah.com/login'
            },
            {
                id: 3,
                sub: 'Log Masuk Ahli Kariah',
                // links: 'https://demo-kariah.al-jariyah.com'
                links: 'https://kariah.al-jariyah.com'
            },
            
            
        ],
    },

    // {
    //     id: 7,
    //     name: 'Contact',
    //     links: '/contact',
    // },
   
    
]

export default menus;